const calendarDefault = (settings) => {
  const getCode = function (settings) {
    return btoa(
      JSON.stringify({
        load: {
          user: settings.element.getAttribute('data-user'),
          ID: settings.element.getAttribute('data-id'),
          demo: false,
          lang: settings.lang
        }
      })
    )
  }

  var script = document.createElement('flix-calendar')
  script.setAttribute('callback', 'calendarSettings')
  script.setAttribute('id', new Date().getTime())
  script.setAttribute('data', getCode(settings))
  settings.element.innerHTML = ''
  settings.element.appendChild(script)
}

const calendarBold = (settings) => {
  var script = document.createElement('flix-calendar-bold')
  script.setAttribute('language', settings.lang)
  script.setAttribute('user', settings.element.getAttribute('data-user'))
  script.setAttribute('calendar', settings.element.getAttribute('data-id'))
  settings.element.innerHTML = ''
  settings.element.appendChild(script)
}

const calendarCalendly = (settings) => {
  var script = document.createElement('flix-calendar-clndly')
  script.setAttribute('language', settings.lang)
  script.setAttribute('user', settings.element.getAttribute('data-user'))
  script.setAttribute('calendar', settings.element.getAttribute('data-id'))
  settings.element.innerHTML = ''
  settings.element.appendChild(script)
}

const getCalendarType = async (settings) => {
  const type = await fetch(
    process.env.VUE_APP_API_URL + '/' + settings.lang + '/assistent/get_data',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: settings.element.getAttribute('data-user'),
        ID: settings.element.getAttribute('data-id')
      })
    }
  ).then((ret) => ret.json())

  if (
    typeof type[0] !== 'undefined' &&
    type[0] &&
    typeof type[1] === 'object' &&
    type[1] &&
    typeof type[1].design !== 'undefined'
  ) {
    return type[1].design
  }
  return 'default'
}

const changeLayout = function () {
  this.lang = ''
  this.translation = {}
  this.type = 'default'
  this.run = function () {
    setTimeout(
      function () {
        this.changeTranslations(
          function () {
            this.changeBookingCalendar()
            this.clickCookieBanner()
          }.bind(this)
        )
      }.bind(this),
      500
    )
  }
  this.clickCookieBanner = function () {
    const cookieBanner = document.getElementById('cookieBanner')
    const btns = cookieBanner.getElementsByClassName('flix-btn')

    for (let i = 0; i < btns.length; i++) {
      btns[i].onclick = function () {
        localStorage.setItem('cookie_policy', 1)
        cookieBanner.remove()
      }
    }
  }
  this.changeTranslations = function (callback) {
    Object.keys(this.translation).forEach(
      function (key) {
        document.body.innerHTML = document.body.innerHTML
          .split(key)
          .join(this.translation[key])
      }.bind(this)
    )
    callback()
  }

  this.changeBookingCalendar = async function () {
    var elements = document.getElementsByClassName('flix-bookingCalendar')
    if (!elements.length) {
      return false
    }
    Array.prototype.forEach.call(elements, async (element) => {
      element.innerHTML = '<flixLoader />'
      this.type = await getCalendarType({ element, lang: this.lang })

      if (element.getAttribute('data-id').includes('ressource')) {
        this.type = 'default'
      }

      switch (this.type) {
        case 'bold':
          calendarBold({ element, lang: this.lang })
          break
        case 'clndly':
          calendarCalendly({ element, lang: this.lang })
          break
        default:
          calendarDefault({ element, lang: this.lang })
      }
    })
  }
}

module.exports = { changeLayout }
