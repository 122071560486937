var standardLayout = [
  '<div class="flix-container-fluid flix-html-html" style="padding: 50px 0; margin-bottom: 10px; background-color:#717C80; color: #fff;">\n  <h1 class="flix-text-center flix-html-h1">:title</h1>\n  <div class="flix-text-center pb_txt">:calendarTitle</div>\n</div>',
  '<div class="flix-container-fluid flix-text-center">\n<a href="#imprint" class="flix-btn flix-btn-xs flix-btn-default">:imprint</a> <a href="#privacy" class="flix-btn flix-btn-xs flix-btn-default">:privacy</a> <a href="#terms" class="flix-btn flix-btn-xs flix-btn-default">:terms</a>\n</div>\n',
  '<div class="flix-container-fluid">\n  <div class="flix-row"  style="padding: 50px 0;">\n    <div class="flix-col-md-12 flix-bookingCalendar" data-id=":id" data-user=":user">\n   <iframe src="/iframe/calendar/:user/:id.html" width="100%" height="400px" frameborder="0" style="overflow-y: hidden"></iframe>\n    </div>\n    <div class="flix-clearfix"></div>\n  </div>\n</div>',
  // '<div class="flix-container-fluid">\n<div class="flix-row" style="margin: 80px 0;">\n<div class="flix-col-md-8 flix-col-md-flix-offset-2">\n<div class="flix-col-md-6 flix-text-center">\n<img src="https://via.placeholder.com/480x320.png?text=Location" class="flix-img-responsive">\n</div>\n<div class="flix-col-md-6 flix-form-group">\n<h1 class="flix-html-h1">:location</h1>\n<p class="flix-html-html pb_txt">:locationTXT</p>\n</div>\n</div>\n</div>\n</div>',
  '<div class="flix-container">\n  <div class="flix-row" style="padding: 20px 0;">\n    <div class="flix-col-md-8 flix-col-md-flix-offset-2 flix-chat" data-user=":user">\n      <iframe width="100%" height="730px" frameborder="0" style="overflow-y: hidden" src="/iframe/chat/:user/:id.html">\n    </div>\n  </div>\n</div>',
  '<div class="flix-container-fluid"><a name="imprint"></a>\n  <div class="flix-row" style="padding: 40px 0;">\n    <div class="flix-col-md-8 flix-col-md-flix-offset-2 flix-imprint" data-user=":user">\n      <h1 class="flix-html-h1">:imprint</h1>\n      <p class="flix-html-html pb_txt">:imprintTXT</p>\n    </div>\n  </div>\n</div>',
  '<div class="flix-container-fluid"><a name="privacy"></a>\n  <div class="flix-row" style="padding: 40px 0;">\n    <div class="flix-col-md-8 flix-col-md-flix-offset-2 flix-privacy" data-user=":user">\n      <h1 class="flix-html-h1">:privacy</h1>\n      <p class="flix-html-html pb_txt">:privacyTXT</p>\n    </div>\n  </div>\n</div>',
  '<div class="flix-container-fluid"><a name="terms"></a>\n  <div class="flix-row" style="padding: 40px 0;">\n    <div class="flix-col-md-8 flix-col-md-flix-offset-2 flix-terms" data-user=":user">\n      <h1 class="flix-html-h1">:terms</h1>\n      <p class="flix-html-html pb_txt">:termsTXT</p>\n    </div>\n  </div>\n</div>']

export { standardLayout }
