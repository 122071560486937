<template>
  <div>
    <div v-if="meta && meta.method && meta.method === 'customUrl'">
      <meta http-equiv="refresh" :content="'0; url=' + layout[0]" />
    </div>
    <div v-else-if="layout">
      <div v-for="(part, index) in layout" :key="index" v-html="part" />
    </div>
    <cookieBanner></cookieBanner>
  </div>
</template>
<script>
import { standardLayout } from '@/components/pageBuilder/standardLayout.js'
import { changeLayout } from '@/components/bookingPage/changeLayout.js'
export default {
  components: {
    cookieBanner: () => import('@/components/cookieBanner/index.vue')
  },
  props: {},
  data () {
    return {
      layout: false,
      userID: false,
      user: false,
      loaded: false,
      meta: false
    }
  },
  methods: {
    getUserData (callback) {
      this.$flix_post({
        url: '/user/get_imprint',
        data: { user: this.userID },
        callback: function (ret) {
          this.user = ret.data[1]
          callback()
        }.bind(this)
      })
    },
    getChangeLayout () {
      if (!this.user.terms) {
        this.user.terms = '---'
      }
      if (!this.user.privacy) {
        this.user.privacy = '---'
      }

      var cl = new changeLayout()
      cl.type = this.$route.params.style
      cl.lang = this.$route.params.lang
      cl.saveSettings = process.env.VUE_APP_CALENDAR_SCRIPT
      cl.translation = {
        ':imprintTXT': this.user.imprint,
        ':privacyTXT': this.user.privacy,
        ':termsTXT': this.user.terms,
        ':imprint': this.$t('message.imprint'),
        ':privacy': this.$t('message.privacy'),
        ':terms': this.$t('message.terms'),
        ':title': this.$tc('message.pageBuilder', 1),
        ':calendarTitle': this.$t('message.save', { name: this.$tc('message.appointments', 1) })
      }
      cl.run()
    },
    getData () {
      this.$store.dispatch('getBookingPage',
        {
          username: this.$route.params.username,
          ID: this.$route.params.id,
          callback: function (ret) {
            this.userID = ret[1]
            this.getUserData(function () {
              if (!ret[0]) {
                if (this.userID) {
                  this.layout = JSON.parse(JSON.stringify(standardLayout)
                    .split(':id').join(this.$route.params.id)
                    .split(':user').join(this.userID))
                }
                this.getChangeLayout()
                return false
              }
              this.layout = JSON.parse(ret[0])
              if (ret[2]) {
                this.meta = JSON.parse(ret[2])
              }
              this.getChangeLayout()
            }.bind(this))
          }.bind(this)
        })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="sass">
  html, body
    padding: 0
    margin: 0
    overflow-x: hidden
</style>
<style lang="sass" scoped>
  .edit
    width: 50px
    height: 50px
    background-color: #ff00ff
    text-align: center
    border-radius: 50%
    font-size: 32px
    color: white
    position: fixed
    right: 40px
    bottom: 40px
    transition: all 0.3s ease-out
    &:hover
      transform: scale(1.5)
</style>
